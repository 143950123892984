import { render, staticRenderFns } from "./WarehouseShipmentsDetList.vue?vue&type=template&id=31af37ec&scoped=true&"
import script from "./WarehouseShipmentsDetList.vue?vue&type=script&lang=js&"
export * from "./WarehouseShipmentsDetList.vue?vue&type=script&lang=js&"
import style0 from "./WarehouseShipmentsDetList.vue?vue&type=style&index=0&id=31af37ec&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31af37ec",
  null
  
)

export default component.exports